// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CategoryCard.css */

.category-card {
    cursor: pointer;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
  }
  
  .category-card img {
    height: 85%;
    width: 100%;
    object-fit: cover;
  }
  
  .category-card-body {
    background-color: #ffffff;
    color: black;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    padding: 2px 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/componentsHome/css/CategoryCard.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;IACT,iCAAiC;IACjC,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":["/* CategoryCard.css */\n\n.category-card {\n    cursor: pointer;\n    width: 100%;\n    height: 100%;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n    overflow: hidden;\n    margin: 0;\n    font-family: 'Roboto', sans-serif;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .category-card img {\n    height: 85%;\n    width: 100%;\n    object-fit: cover;\n  }\n  \n  .category-card-body {\n    background-color: #ffffff;\n    color: black;\n    font-size: 0.75rem;\n    font-weight: 500;\n    text-align: center;\n    padding: 2px 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
