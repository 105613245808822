// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-group-card {
  border: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin: 0;
  padding: 0;
}

.category-group-title {
  font-size: 1.5rem; /* Tamaño reducido en un 40-50% */
  font-weight: 600;
  color: #2c3e50; /* Color profesional */
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Helvetica Neue', sans-serif;
  letter-spacing: 0.5px; /* Espaciado ligero para un estilo moderno */
}

.category-group-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background-color: #3498db; /* Color de acento */
  margin: 0.5rem auto 1rem;
  border-radius: 2px;
}

.category-col {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0;
}

.category-col:last-child {
  border-right: none;
}

.category-card-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/componentsHome/css/CategoryGroup.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB;EACnB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB,EAAE,iCAAiC;EACpD,gBAAgB;EAChB,cAAc,EAAE,sBAAsB;EACtC,kBAAkB;EAClB,mBAAmB;EACnB,yCAAyC;EACzC,qBAAqB,EAAE,4CAA4C;AACrE;;AAEA;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,WAAW;EACX,yBAAyB,EAAE,oBAAoB;EAC/C,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".category-group-card {\n  border: 1px solid #ccc;\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n  margin-bottom: 1rem;\n  margin: 0;\n  padding: 0;\n}\n\n.category-group-title {\n  font-size: 1.5rem; /* Tamaño reducido en un 40-50% */\n  font-weight: 600;\n  color: #2c3e50; /* Color profesional */\n  text-align: center;\n  margin-bottom: 1rem;\n  font-family: 'Helvetica Neue', sans-serif;\n  letter-spacing: 0.5px; /* Espaciado ligero para un estilo moderno */\n}\n\n.category-group-title::after {\n  content: '';\n  display: block;\n  width: 60px;\n  height: 3px;\n  background-color: #3498db; /* Color de acento */\n  margin: 0.5rem auto 1rem;\n  border-radius: 2px;\n}\n\n.category-col {\n  border-right: 1px solid #ccc;\n  border-bottom: 1px solid #ccc;\n  padding: 0;\n}\n\n.category-col:last-child {\n  border-right: none;\n}\n\n.category-card-wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
