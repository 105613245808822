// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/home/componentsHome/Map.css */

.map-wrapper {
  direction: ltr;
  margin: 0;
  padding: 0;
}

.map-container {
  height: 500px;
  width: 100%;
  transition: filter 0.3s ease;
  margin: 0;
  padding: 0;
}

.map-description {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
}

.map-highlight {
  color: #007bff;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-size: 1.2rem;
  color: #555;
  margin: 0;
  padding: 0;
}

.falcon-component-card-body {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/componentsHome/css/Map.css"],"names":[],"mappings":"AAAA,+CAA+C;;AAE/C;EACE,cAAc;EACd,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,WAAW;EACX,4BAA4B;EAC5B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EACjB,WAAW;EACX,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":["/* src/components/home/componentsHome/Map.css */\n\n.map-wrapper {\n  direction: ltr;\n  margin: 0;\n  padding: 0;\n}\n\n.map-container {\n  height: 500px;\n  width: 100%;\n  transition: filter 0.3s ease;\n  margin: 0;\n  padding: 0;\n}\n\n.map-description {\n  font-size: 1.1rem;\n  line-height: 1.6;\n  color: #333;\n}\n\n.map-highlight {\n  color: #007bff;\n}\n\n.loading-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 300px;\n  font-size: 1.2rem;\n  color: #555;\n  margin: 0;\n  padding: 0;\n}\n\n.falcon-component-card-body {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
